import React from 'react';
import PropTypes from "prop-types";
import GetTranslation from "../../../helper/GetTranslation";
import GlobalTrans from "../../../helper/GlobalTrans";
import config from "../../../config/config";

const RendererVideo = (props) => {
    const getVideoSource = () => {
        const src = GetTranslation({
            translations: props.element?.translations,
            defaultContent: props.element.src,
            translationField: 'src'
        });

        if (src?.contentUrl) {
            return config.apiHost + src.contentUrl;
        }

        return null;
    }

    return (
        <video controls={!props.element.deactivateControls} width={props.element.width} poster={props.element.thumb} src={getVideoSource()}>
            {GlobalTrans('browser_doesnt_support_video')}
        </video>
    );
};

RendererVideo.propTypes = {
    element: PropTypes.object.isRequired
}

export default RendererVideo;